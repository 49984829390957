<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :data="list"
      :title="tableTitle"
      :options="{ operatesWidth: 170 }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '证书报名记录'
const btns = _this => [
  {
    label: '返回',
    method: _this.handleReturn,
  },
]

// 表格列配置
const columns = () => [
  {
    prop: 'certName',
    label: '证书名称',
    minWidth: '130px',
  },
  {
    prop: 'certLevelShow',
    label: '证书等级',
    minWidth: '150px',
  },
  {
    prop: 'appointNumber',
    label: '报名人数',
    minWidth: '136px',
  },
  {
    prop: 'createTime',
    label: '确认报名时间',
    minWidth: '150px',
    sortdata: 1,
  },
]

// 表格操作按钮配置
const operates = _this => [
  {
    label: '查看详细记录',
    // isShow: item => item.publishStatus == 0 || item.publishStatus == 2,
    method: _this.handleDetail,
  },
]

import total from '../../../assets/address/city'
import TableList from '@/components/TableList'
import { getPageList } from '@/api/certCenter'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      btns,
      operates,
      columns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      total,
      list: [],
    }
  },
  created() {
    this.certificateList()
  },
  methods: {
    async certificateList() {
      //parseInt(this.$route.query.appointId) // certPrivateId:certPrivateId
      console.log(this.$route.query.certPrivateId)
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(
        getPageList({ current, size, certPrivateId: parseInt(this.$route.query.certPrivateId) }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list.map(item => ({
        ...item,
        cityStr: item.provinceName + '-' + item.cityName,
        certLevelShow: item.certLevelShow != null ? item.certLevelShow : '--',
        appointNumber: item.appointNumber != null ? item.appointNumber : '--',
      }))
      this.pager.total = res.data.total
    },
    handleEdit(row) {
      this.$router.push(`/certCenter/add/${row.certId}`)
    },
    handleAppoint(row) {
      this.$router.push(`/certCenter/appoint/${row.certId}`)
    },
    handleDetail(row) {
      this.$router.push({
        path: '/certCenter/detailItem',
        query: {
          certRelationTrainId: row.certRelationTrainId,
        },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.certificateList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.certificateList()
    },
    handleReturn() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss"></style>
